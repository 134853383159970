<template>
  <div class="my-mind-content-father-box">
    <div :class="show == 1 ? 'my-mind-content-1' : 'my-mind-content-2'">
      <div
        v-if="show == 1"
        class="my-mind-list-head my-mind-list"
        style="background-color: #f5f5f5; height:30px"
      >
        <div class="my-mind-list-text">
          <div class="my-mind-main-heading" style="padding-left: 10px">
            {{ getString(strings.Mind_Mindmap_Deleted_Filename) }}
          </div>
        </div>
        <div
          :style="{ marginLeft: (fullWidth - titleExceptWidth + 10) + 'px' }"
          class="my-mind-time-title"
        >
          {{ getString(strings.Edit_Time) }}
        </div>
      </div>
      <template v-if="data != null">
        <div
          v-for="(item, index) in data"
          :key="index"
          class="my-mind-list-box"
          @contextmenu.prevent.stop="showMenu(item, !showMenuOpen, index)"
        >
          <div v-if="item.isDir == false" class="my-mind-list" @mouseleave="gridCloseMoreMenu()">
            <template v-if="isElectron">
              <div
                :id="'my-mind-list' + item.mindMapId"
                class="my-mind-list hover-mind-list"
                @click="openMind(item)"
              >
                <img
                  class="my-mind-img"
                  src="../../../assets/img/user/mind_file_icon.svg"
                  v-real-img="checkImgSrc(item.cover, item)"
                  alt=""
                />
                <img
                  class="local-cloud-icon"
                  v-if="(item.isHistory == null || item.isHistory == false) && item.path != null && item.path.length > 0"
                  src="../../../assets/img/user/local_cloud_icon.svg"
                  alt=""
                  title="已同步"
                />
                <div class="my-mind-list-text">
                  <div
                    v-if="item.alias == '' || item.alias == null"
                    class="my-mind-main-heading"
                    :style="{ width: fullWidth - titleExceptWidth + 'px' }"
                    :title="item.name"
                  >
                    {{ item.name }}
                  </div>
                  <div
                    v-else
                    class="my-mind-main-heading"
                    :style="{ width: fullWidth - titleExceptWidth + 'px' }"
                    :title="item.alias"
                  >
                    {{ item.alias }}
                  </div>
                  <div
                    class="my-mind-main-subheading"
                    :style="{ width: fullWidth - titleExceptWidth + 'px' }"
                  >
                    {{ item.subtitle }}
                  </div>
                </div>
                <div class="my-mind-time">
                  {{
                    dateFormat(
                      "YYYY/mm/dd HH:MM",
                      new Date(item.modifyTime * 1000)
                    )
                  }}
                </div>
              </div>
            </template>
            <template v-else>
              <a
                class="my-mind-item"
                :id="'my-mind-list' + item.mindMapId"
                :href="'/mindmap?id=' + item.mindMapId + '&t=' + item.modifyTime"
                target="_blank"
              >
                <!-- <img v-if="item.cover != ''" class="my-mind-img" :src="checkImgSrc(item.cover)" alt=""> -->
                <img
                  class="my-mind-img"
                  src="../../../assets/img/user/mind_file_icon.svg"
                  v-real-img="checkImgSrc(item.cover)"
                  alt=""
                />
                <div class="my-mind-list-text">
                  <div
                    class="my-mind-main-heading"
                    :style="{ width: fullWidth - titleExceptWidth + 'px' }"
                    :title="item.name"
                  >
                    {{ item.name }}
                  </div>
                  <div
                    class="my-mind-main-subheading"
                    :style="{ width: fullWidth - titleExceptWidth + 'px' }"
                  >
                    {{ item.subtitle }}
                  </div>
                </div>
                <div class="my-mind-time">
                  {{
                    dateFormat(
                      "YYYY/mm/dd HH:MM",
                      new Date(item.modifyTime * 1000)
                    )
                  }}
                </div>
              </a>
            </template>

            <!-- <div @click="pageFile(3,item)" class="gray-box">
                </div> -->
            <div
              class="more-menu-list"
              @click.stop="showMenu(item, !showMenuOpen, index)"
            >
              <!-- @contextmenu.prevent.stop="" -->
              <!-- @mouseover.stop="mouseoverOtherMoreMenu(index)" -->
              <a-icon class="more-icon" type="more" />
              <div
                v-show="
                  !!showMenuData &&
                  showMenuData.id == item.id &&
                  showMenuOpen == true
                "
                class="more-menu-list-position"
                :class="'more-menu-list-position-' + index"
                :style="{ top: menuTop + 'px',right:menuRight + 'px' }"
              >
                <div @click.stop="pageFile(3, item)" class="each-menu-box">
                  <img
                    class="each-menu-box-icon"
                    src="../../../assets/img/myMindIcon/open01.png"
                    alt=""
                  />
                  <div class="each-menu-box-name">
                    {{ getString(strings.My_Mind_Open) }}
                  </div>
                </div>
                <!-- 重命名 -->
                <!-- <div @click.stop="folderRenameMap(item,index)" class="each-menu-box">
                  <img class="each-menu-box-icon-1" src="../../../assets/img/myMindIcon/copyMap.png" alt="">
                  <div class="each-menu-box-name">
                      {{ getString(strings.User_Folder_Rename) }}
                  </div>
                </div> -->
                <div
                  v-show="item.isHistory == null || item.isHistory == false"
                  @click.stop="folderRenameMap(item, index)"
                  class="each-menu-box"
                >
                  <img
                    class="each-menu-box-icon-1"
                    src="../../../assets/img/myMindIcon/rename02.png"
                    alt=""
                  />
                  <div class="each-menu-box-name">
                    {{ getString(strings.User_Folder_Rename) }}
                  </div>
                </div>
                <!-- 复制导图 -->
                <div 
                v-show="item.isHistory == null || item.isHistory == false"
                @click.stop="copyMap(item, index)" class="each-menu-box">
                  <img
                    class="each-menu-box-icon-1"
                    src="../../../assets/img/myMindIcon/copyMap.png"
                    alt=""
                  />
                  <div class="each-menu-box-name">
                    {{ getString(strings.Copy_Mind_Map) }}
                  </div>
                </div>
                <!-- <div  @click.stop="shareFile(item,index)" class="each-menu-box">
                    <img class="each-menu-box-icon-1" src="../../../assets/img/myMindIcon/delete04.png" alt="">
                    <div class="each-menu-box-name">
                      分享风暴
                    </div>
                  </div> -->
                <div 
                v-show="item.isHistory == null || item.isHistory == false"
                @click.stop="moveCheck(item, index)" class="each-menu-box">
                  <img
                    class="each-menu-box-icon-1"
                    src="../../../assets/img/myMindIcon/set03.png"
                    alt=""
                  />
                  <div class="each-menu-box-name">
                    {{ getString(strings.User_Move_To) }}
                  </div>
                </div>
                <div v-show="item.isHistory == null || item.isHistory == false"
                  @click.stop="importGroup(item, index)"
                  class="each-menu-box"
                >
                  <img
                    class="each-menu-box-icon-1"
                    src="../../../assets/img/myMindIcon/export_group.svg"
                    alt=""
                  />
                  <div class="each-menu-box-name">
                    {{
                      getString(strings.Mind_Mind_Import_Into_A_Shared_Group)
                    }}
                  </div>
                </div>
                <div
                  v-show="item.isHistory == null || item.isHistory == false"
                  @click.stop="importArchive(item, index)"
                  class="each-menu-box"
                >
                  <img
                    class="each-menu-box-icon-1 archive"
                    src="../../../assets/img/myMindIcon/archive.svg"
                    alt=""
                  />
                  <div class="each-menu-box-name">
                    {{ getString(strings.Mind_Archive) }}
                  </div>
                </div>
                <div
                  @click.stop="removeFile(item, index)"
                  class="each-menu-box"
                >
                  <img
                    class="each-menu-box-icon-1"
                    src="../../../assets/img/myMindIcon/delete04.png"
                    alt=""
                  />
                  <div class="each-menu-box-name">
                    {{ getString(strings.Mind_Mindmap_Deleted_Forever) }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div @click="pageFile(3, item)" @mouseleave="gridCloseMoreMenu()" v-else class="my-mind-list">
            <span
              v-if="item.colorCode == 0 || item.colorCode == ''"
              class="fileiconfont my-mind-icon file-color-blue"
              >&#xe761;</span
            >
            <span
              v-if="item.colorCode == 1"
              class="fileiconfont my-mind-icon file-color-yellow"
              >&#xe761;</span
            >
            <span
              v-if="item.colorCode == 2"
              class="fileiconfont my-mind-icon file-color-red"
              >&#xe761;</span
            >
            <span
              v-if="item.colorCode == 3"
              class="fileiconfont my-mind-icon file-color-green"
              >&#xe761;</span
            >
            <span
              v-if="item.colorCode == 4"
              class="fileiconfont my-mind-icon file-color-gray"
              >&#xe761;</span
            >
            <div class="my-mind-list-text">
              <div
                class="my-mind-main-heading"
                :style="{ width: fullWidth - titleExceptWidth + 'px' }"
                :title="item.name"
              >
                {{ item.name }}
              </div>
              <div
                class="my-mind-main-subheading"
                :style="{ width: fullWidth - titleExceptWidth + 'px' }"
              >
                {{ item.subtitle }}
              </div>
            </div>
            <div class="my-mind-time">
              {{
                dateFormat("YYYY/mm/dd HH:MM", new Date(item.modifyTime * 1000))
              }}
            </div>

            <div
              class="more-menu-list"
              @click.stop="showMenu(item, !showMenuOpen, index)"
            >
              <!-- @mouseover.stop="mouseoverOtherMoreMenu(index)"
              @contextmenu.prevent.stop="" -->
              <a-icon class="more-icon" type="more" />
              <div
                v-show="
                  !!showMenuData &&
                  showMenuData.id == item.id &&
                  showMenuOpen == true
                "
                class="more-menu-list-position"
                :class="'more-menu-list-position-' + index"
                :style="{ top: menuTop + 'px',right:menuRight + 'px' }"
                v-clickoutside="outsideCloseMoreMenu"
              >
                <div @click.stop="pageFile(3, item)" class="each-menu-box">
                  <img
                    class="each-menu-box-icon"
                    src="../../../assets/img/myMindIcon/open01.png"
                    alt=""
                  />
                  <div class="each-menu-box-name">
                    {{ getString(strings.My_Mind_Open) }}
                  </div>
                </div>
                <div @click.stop="moveCheck(item, index)" class="each-menu-box">
                  <img
                    class="each-menu-box-icon-1"
                    src="../../../assets/img/myMindIcon/set03.png"
                    alt=""
                  />
                  <div class="each-menu-box-name">
                    {{ getString(strings.User_Move_To) }}
                  </div>
                </div>

                <div
                  @click.stop="repeatName(item, index)"
                  class="each-menu-box"
                >
                  <img
                    class="each-menu-box-icon-1"
                    src="../../../assets/img/myMindIcon/rename02.png"
                    alt=""
                  />
                  <div class="each-menu-box-name">
                    {{ getString(strings.User_Folder_Rename) }}
                  </div>
                </div>

                <div
                  @click.stop="removeFile(item, index)"
                  class="each-menu-box"
                >
                  <img
                    class="each-menu-box-icon-1"
                    src="../../../assets/img/myMindIcon/delete04.png"
                    alt=""
                  />
                  <div class="each-menu-box-name">
                    {{ getString(strings.Mind_Mindmap_Deleted_Forever) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <!-- <div
      v-if="show == 2"
      :class="show == 1 ? 'my-mind-content-1' : 'my-mind-content-2'"
    >
      <div v-for="(item, index) in data" :key="index">
        <div class="collles">
          <div class="colimg">
            <img src="../../../assets/img/user/plate_plan_4.jpg" alt="" />
          </div>
          <div>
            <p class="colzi">头脑风暴头脑风暴</p>
            <p class="colriqi" style="padding-bottom: 10px">2020.11.11</p>
            <a-popover
              class="more-father-box"
              @visibleChange="hidden"
              v-if="showPop == 0"
              placement="bottom"
              trigger="hover"
            >
              <template slot="content">
                <div>{{ getString(strings.My_Mind_Open) }}</div>
                <div @click="moveCheck(item, index)">
                  {{ getString(strings.User_Move_To) }}
                </div>
                <div @click="removeFile(item, index)">
                  {{ getString(strings.Mind_Mindmap_Deleted_Forever) }}
                </div>
                <div>
                  {{ getString(strings.User_Folder_Rename) }}
                </div>
              </template>
              <a-icon class="more-function" type="more" />
            </a-popover>
          </div>
        </div>
      </div>
    </div> -->
    <div class="mind-null" v-if="data == null || data.length == 0">
      <p>
        <!-- <img src="../../../assets/img/user/map1135.png" alt=""> -->
        <img class="createdMindEmptyIcon"
          src="../../../../static/img/userData/user_collection_null_logo_MyMind.svg"
          alt=""
        />
      </p>
      <!-- {{getString(strings.My_Mind__No_Map_Yet_Go)}}  <span class="createdMind" @click="createdMindPage"> {{getString(strings.My_Mind_Create)}} </span>  {{getString(strings.My_Mind_Right)}}  -->
      <div class="createdMind">
        {{ getString(strings.My_Mind_Is_Null_Tips) }}
      </div>
    </div>
  </div>
</template>

<script>
import HttpServerConfig from "../../../common/HttpServerConfig";
import { mapMutations } from "vuex";
import Template from "../../../views/template/Template.vue";
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
import ConfigurationItem from "../../../assets/js/ConfigurationItem.js";
const { numberOfMaps } = ConfigurationItem;
import Config from "../../../core/core/calcule/Config";
import Util from "../../../utils/Util";
import XJMindFIleImport from '../../../core/core/dataformat/fileImport/XJMindFIleImport';

if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  var { ipcRenderer } = window.require("electron");
}
export default {
  name: "MyMindContent",
  props: ["show", "data", "showPop"],
  data() {
    return {
      fullWidth: document.documentElement.clientWidth,
      fullHeight: document.documentElement.clientHeight,
      showMenuData: {}, //当前展示的菜单的对象
      showMenuOpen: false, //菜单是否展开
      isElectron: false, //是否是electron
      strings,
      showIndex: -1,
      menuTop: 0,
      menuRight: 30,
      titleExceptWidth: 520,
      timerOtherMoreMenu: null,
    };
  },
  created() {
    this.isElectron = this.$tools.isElectron();
  },
  mounted() {
    this.get_bodyWidth();
  },
  methods: {
    ...mapMutations(["sharePotatoChipsModel"]),
    getString(i) {
      return getString(i);
    },
    get_bodyWidth() {
      //动态获取浏览器高度
      const that = this;
      window.addEventListener("resize", () => {
        that.fullWidth = document.documentElement.clientWidth;
        that.fullHeight = document.documentElement.clientHeight;
      });
    },
    checkImgSrc(src, item) {
      if (item != null && item.isHistory) {
        return "../../../assets/img/user/mind_file_icon.svg"
      }
      return "../../../assets/img/user/mind_file_icon.svg";//"HttpServerConfig.getMediaSrc(src)";
    },
    showMenu(item, isCheck, index) {
      this.showMenuData = item;
      //菜单是否展开
      if (this.showIndex == index) {
        //点击相同的菜单显示或隐藏，不同的菜单显示
        this.showMenuOpen = isCheck;
      } else {
        this.showMenuOpen = true;
      }
      if (index != undefined && index >= 0) {
        this.showIndex = index;
      }
      this.$emit("closeRightMenu");
      // let event = window.event;
      // let left = event.offsetY;
      // this.menuRight = left
      // console.log(left,event);
    },
    mouseoverOtherMoreMenu(index) {
      // if (this.showMenuOpen) {
      //   clearTimeout(this.timerOtherMoreMenu)
      //   this.timerOtherMoreMenu = setTimeout( () => {
      //     console.log(this.showIndex);
      //     if (this.showIndex != index) {
      //       this.showMenuOpen = false;
      //     }
      //     this.showIndex = index;
      //   },200)
      // }
      if (this.showMenuOpen) {
        if (this.showIndex != index) {
          this.showMenuOpen = false;
        }
        this.showIndex = index;
      }
    },
    outsideCloseMoreMenu() {
      this.showMenuOpen = false;
    },
    gridCloseMoreMenu() {
      if (this.show == 2) {
        this.outsideCloseMoreMenu();
      }
    },
    shareFile(item, index) {
      this.sharePotatoChipsModel({ accout: true, shareData: item });
    },
    //重命名
    folderRenameMap(item, index) {
      let obj = {};
      obj.item = item;
      obj.index = index;
      this.$emit("folderRenameMap", obj);
    },
    copyMap(item, index) {
      let obj = {};
      obj.item = item;
      obj.index = index;
      this.$emit("copyMap", obj);
    },
    moveCheck(item, index) {
      let obj = {};
      obj.item = item;
      obj.index = index;
      this.$emit("moveCheck", obj);
      this.outsideCloseMoreMenu();
    },
    removeFile(item, index) {
      if (item.isHistory && item.path != null) {
        new XJMindFIleImport(null, null, this.$router.appPath).removeHistory(item.path, item.localpath, item.name)
        let count = this.data.length
        for (let index = 0; index < count; index++) {
          if (this.data[index].isHistory && this.data[index].path == item.path) {
              this.data.splice(index, 1)
              break
          }
        }
        let id = 'my-mind-list' + item.mindMapId
        let element = document.getElementById(id)
        if (element != null && element.parentElement != null &&  element.parentElement.parentElement != null) {
          element.parentElement.parentElement.remove()
        }
      } else {
        let obj = {};
        obj.index = index;
        obj.item = item;

        this.$emit("removeFile", obj);
      }
      
      this.outsideCloseMoreMenu();
    },
    importGroup(item, index) {
      let obj = {};
      obj.index = index;
      obj.item = item;
      this.$emit("importGroup", obj);
      this.outsideCloseMoreMenu();
    },
    importArchive(item, index) {
      let obj = {};
      obj.index = index;
      obj.item = item;
      this.$emit("importArchive", obj);
      this.outsideCloseMoreMenu();
    },
    openMind(item) {
      //打开导图
      if (item.isHistory) {
        if (item.id != null && item.id.length > 10 && item.id.indexOf("-local") == -1) {
            ipcRenderer.send("openCalendarWindow", "/mindmap?path=" + encodeURIComponent(item.path) +
                            "&localpath=" + encodeURIComponent(item.localpath) + 
                            "&id=" + item.id + 
                            "&t=" + item.modifyTime);
        } else {
            ipcRenderer.send("openCalendarWindow", "/mindmap?path=" + encodeURIComponent(item.path) +
                            "&localpath=" + encodeURIComponent(item.localpath) + 
                            "&t=" + item.modifyTime);
        }
        new XJMindFIleImport(null, null, this.$router.appPath).addHistory(item.path, item.localpath, item.name, item.id)
      } else {
        if (item.path != null && item.path.length > 0 && item.localpath != null && item.localpath.length > 0) {
          ipcRenderer.send("openCalendarWindow", "/mindmap?id=" + item.mindMapId + 
                            "&path=" + encodeURIComponent(item.path) + 
                            "&localpath=" + encodeURIComponent(item.localpath));
        } else {
          ipcRenderer.send("openCalendarWindow", "/mindmap?id=" + item.mindMapId + 
                            "&t=" + item.modifyTime);
        }
      }
    },
    pageFile(index, item) {
      if (!item.isDir) {
        //如果是点击打开导图
        let aLink = document.getElementById("my-mind-list" + item.mindMapId);
        aLink.click();
        return;
      }
      let obj = {};
      obj.index = index;
      obj.item = item;
      this.$emit("pageFile", obj);
    },
    // 重命名文件夹
    repeatName(item, index) {
      let obj = {};
      obj.index = index;
      obj.item = item;
      this.$emit("repeatName", obj);
      this.outsideCloseMoreMenu();
    },
    hidden() {},
    createdMindPage() {
      this.$router.push("/Home/CreatedFile");
    },
    // 已登录免费用户导图数量超过2个，则每次打开都弹购买弹窗
    mapsNumberMoreThanTwo() {
      const mapsNumber = localStorage.getItem("mapsNumber") || 0;
      const meMessageStr = localStorage.getItem("meMessage");
      const meMessage = meMessageStr != null ? JSON.parse(meMessageStr) : null;
      if (mapsNumber >= numberOfMaps && meMessage && !meMessage.isMember) {
        // 已登录 弹付费窗 30分钟弹出一次
        let showShoppingMoreThanTwoTime = "showShoppingMoreThanTwoTime";
        let showShoppingTime = localStorage.getItem(
          showShoppingMoreThanTwoTime
        );
        let nowTime = Util.getCurrentTime();
        if (showShoppingTime) {
          let time = nowTime - showShoppingTime;
          if (time > Config.MAPS_MORE_THAN_TWO_TIME) {
            this.$store.state.showShopping = true;
            localStorage.setItem(showShoppingMoreThanTwoTime, nowTime);
          }
        } else {
          localStorage.setItem(showShoppingMoreThanTwoTime, nowTime);
          let firstOpenPage = sessionStorage.getItem("firstOpenPage");
          if (!firstOpenPage) {
            sessionStorage.setItem("firstOpenPage", true);
            this.$store.state.showShopping = true;
          }
        }
      }
    },
    dateFormat(fmt, date) {
      let ret;
      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },
  },
  watch: {
    data(newVal) {
      // if (newVal) {
      //   let len = newVal.length;
      //   for (let i = 0; i < len; i++) {
      //     let date = new Date(newVal[i].modifyTime * 1000);
      //     newVal[i].modifyTime = this.dateFormat("YYYY/mm/dd HH:MM", date);
      //   }
      // }
      localStorage.setItem("mapsNumber", this.data.length);
    },
    showIndex: {
      handler(newVal) {
        var e = window.event;
        if (e.pageY + 392 > this.fullHeight) {
          this.menuTop = this.fullHeight - (e.pageY + 392);
        } else {
          this.menuTop = 0;
        }
        if (this.showMenuData.isDir && e.pageY + 232 > this.fullHeight) {
          this.menuTop = this.fullHeight - (e.pageY + 232);
        }
      },
    },
  },
  destroyed() {},
};
</script>

<style  lang="less">
.my-mind-content-father-box {
  /* 文件夹颜色开始 */
  .file-color-green {
    color: #43a102;
  }
  .file-color-blue {
    color: #4499ee;
  }
  .file-color-red {
    color: #ff6600;
  }
  .file-color-yellow {
    color: #eed205;
  }
  .file-color-gray {
    color: #707070;
  }
  /* 文件夹颜色结束 */

  .mind-null {
    /* height: 100px; */
    padding-top: 7em;
    color: #ccc;
    padding-bottom: 40px;
    text-align: center;
    /* line-height: 100px; */
    font-size: 18px;
  }

  /* 列表视图 */
  .my-mind-content-1 {
    margin-top: 3em;
  }

  .my-mind-content-1 .my-mind-list {
    position: relative;
    display: flex;
    height: 60px;
    align-items: center;
    border-bottom: 1px solid #eee;
  }
  .my-mind-content-1 .my-mind-item {
    height: inherit;
    position: relative;
    display: flex;
    align-items: center;
  }
  .my-mind-content-1 .my-mind-list:hover {
    // background-color: #ddd;
    background-color: #eee;
  }

  .my-mind-content-1 .my-mind-list .my-mind-img {
    display: block;
    width: 40px;
    height: 40px;
    font-size: 30px;
    line-height: 31px;
    text-align: center;
    margin-left: 20px;
    // border: 1px solid #e9e9e9; 
    /* box-shadow: 0 0 5px #999; */
    border-radius: 6px;
    border-radius: 2px;
  }
  .local-cloud-icon {
    position:absolute;
    width: 20px;
    height: 20px;
    left: 68px;
    top: 30px;
  }
  .my-mind-content-1 .my-mind-list .my-mind-icon {
    display: block;
    width: 40px;
    height: 40px;
    font-size: 40px;
    line-height: 41px;
    text-align: center;
    margin-left: 20px;
    cursor: pointer;
  }
  .my-mind-content-1 .my-mind-list .my-mind-list-text {
    margin: 0 20px 0 15px;
  }
  .my-mind-content-1 .my-mind-list .my-mind-main-heading {
    font-size: 14px;
    font-family: Noto Sans SC;
    font-weight: 400;
    color: #333333;
    cursor: pointer;
    min-width: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .my-mind-content-1 .my-mind-list .my-mind-main-subheading {
    width: 100%;
    /* width: 240px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #999;
    box-sizing: border-box;
  }
  .my-mind-content-1 .my-mind-list .my-mind-time {
    // margin-left: auto;
    // margin-right: 40px;
    font-size: 12px;
    font-family: Noto Sans SC;
    font-weight: 400;
    color: #333333;
  }
  .my-mind-content-1 .gray-box {
    position: absolute;
    /* visibility:hidden; */
    /* z-index: -1; */
    opacity: 0;
  }
  .my-mind-content-1 .my-mind-list:hover .gray-box {
    opacity: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 1;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    visibility: visible;
  }

  /* .my-mind-content-1 .my-mind-list:hover .gray-box-active{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.1);
  z-index: 1;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
} */
  .my-mind-content-1 .more-father-box {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    /* background-color: lime; */
  }
  .my-mind-content-1 .more-function {
    margin-left: auto;
    margin-right: 20px;
    color: #333;
  }
  .my-mind-content-1 .my-mind-time-title {
    // margin-left: auto;
    margin-right: 55px;
  }

  .my-mind-content-1 .my-mind-list-head .my-mind-img {
    box-shadow: 0 0 5px #fff;
  }

  .more-menu-list {
    text-align: center;
    width: 40px;
    height: 30px;
    margin-left: 20px;
    position: relative;
    line-height: 30px;
    cursor: pointer;
    .more-icon {
      color: #333;
      font-size: 16px;
      // position: absolute;
      // z-index: 1;
      // left: 50%;
      // top: 50%;
      // transform: translate(-50%,-50%);
    }
    .more-menu-list-position {
      // display: none;
      position: absolute;
      z-index: 2;
      right: 30px;
      top: 0;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 2px 2px 10px #a1a0a0;
      overflow: hidden;
      .each-menu-box {
        display: flex;
        // width: 140px;
        align-items: center;
        // padding-left: 15px;
        padding-top: 8px;
        padding-bottom: 8px;

        .each-menu-box-icon {
          width: 12px;
          height: 7.5px;
          display: block;
          margin-right: 15px;
          margin-left: 30px;
        }
        .each-menu-box-icon-1 {
          width: 12px;
          height: 12px;
          display: block;
          margin-right: 15px;
          margin-left: 30px;
        }

        .each-menu-box-name {
          white-space: nowrap;
          margin-right: 30px;
        }
        &:hover {
          background-color: #eee;
        }
      }
    }
    &:hover {
      display: block;
    }
  }

  /* 网格视图 */
  .my-mind-content-2 {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-left: 0px;
    // .my-mind-list-box{
    //   // margin: 0 auto;
    // }
    
    .my-mind-list {
      width: 116px;
      max-height: 116px;
      margin: 10px 0px 10px 5px;
      padding: 10px;
      position: relative;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
       &:hover {
        // transition: 0.3s all;
        background: #f4f4f5;
        border: 1px solid #e9e9e9;
        border-radius: 10px;
       
      }
      .hover-mind-list{
        &:hover {
          border: 0;
        
        }
      }
      &:hover .more-menu-list {
        display: block;
      }
      .my-mind-icon {
        width: 42px;
        height: 42px;
        font-size: 42px;
        line-height: 42px;
        text-align: center;
        margin-bottom: 15px;
        margin-top: 15px;
        cursor: pointer;
      }
      .my-mind-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .my-mind-img {
        width: 40px;
        height: 40px;
        display: block;
        margin-bottom: 10px;
        margin-top: 15px;
        // background: #ffffff;
        // border: 1px solid #e9e9e9;
        border-radius: 4px;
        cursor: pointer;
      }
      .local-cloud-icon {
        position: absolute;
        width: 20px;
        height: 20px;
        left: 66px;
        top: 42px;
      }

      .my-mind-main-heading {
        width: 96px !important;
        height: 20px;
        font-size: 14px;
        cursor: pointer;
        color: #333333;
        text-align: center;
        // white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .more-menu-list {
        width: 40px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        margin-left: 20px;
        position: absolute;
        top: 0;
        right: 0;
        display: none;
        .more-icon {
          color: #333;
          font-size: 16px;
          // position: absolute;
          // z-index: 1;
          // left: 50%;
          // top: 50%;
          // transform: translate(-50%,-50%);
        }
        .more-menu-list-position {
          // display: none;
          position: absolute;
          z-index: 2;
          right: 30px;
          top: 0;
          background-color: #fff;
          border-radius: 8px;
          box-shadow: 2px 2px 10px #a1a0a0;
          overflow: hidden;
          margin-top: 10px;
          .each-menu-box {
            display: flex;
            // width: 140px;
            align-items: center;
            // padding-left: 15px;
            padding-top: 8px;
            padding-bottom: 8px;

            .each-menu-box-icon {
              width: 12px;
              height: 7.5px;
              display: block;
              margin-right: 15px;
              margin-left: 30px;
            }
            .each-menu-box-icon-1 {
              width: 12px;
              height: 12px;
              display: block;
              margin-right: 15px;
              margin-left: 30px;
            }

            .each-menu-box-name {
              white-space: nowrap;
              margin-right: 30px;
            }
            &:hover {
              background-color: #eee;
            }
          }
        }
        &:hover {
          display: block;
        }
      }
    }
  }
  .my-mind-content-2 .collles {
    width: 180px;
    margin: 10px 0px;
    position: relative;
    background: #ffffff;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 0px 0px 4px 4px;
  }
  .my-mind-content-2 .collles p {
    padding-left: 20px;
    margin: unset;
  }
  .my-mind-content-2 .collles .colimg img {
    width: 180px;
    height: 160px;
  }
  .my-mind-content-2 .collles .colzi {
    font-size: 16px;
    padding-top: 10px;
    font-family: Noto Sans SC;
    /*font-weight: bold;*/
    color: #333333;
  }
  // .my-mind-content-2 .collles .colzi .colriqi{

  // }
  .my-mind-content-2 .my-mind-list .my-mind-time {
    font-size: 12px;
    font-family: Noto Sans SC;
    font-weight: 400;
    color: #333333;
    display: none;
  }
  .my-mind-content-2 .my-mind-main-subheading {
    display: block;
    width: 96px !important;
    height: 20px;
    font-size: 12px;
    cursor: pointer;
    color: #999999;
    text-align: center;
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  /* .my-mind-content-2 .more-function{
  position: absolute;
  right: 0;
  top: 0;
} */
  .my-mind-content-2 .more-father-box {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 5px;
    /* transform:translateY(-50%); */
    cursor: pointer;
    /* background-color: lime; */
  }
  // .ant-popover-inner-content div:hover{
  //   color: #000;
  // }

  .my-mind-content-2 .more-function {
    margin-left: auto;
    /* margin-right: 20px; */
    color: #666;
  }

  .my-mind-content-2 i.null-box {
    width: 70px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .my-mind-content-2 .gray-box {
    display: none;
  }
  .my-mind-content-2 .my-mind-list:hover .gray-box {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 1;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
  }
  .createdMind {
    width: 292px;
    height: 40px;
    font-size: 14px;
    font-family: Noto Sans SC;
    font-weight: 400;
    line-height: 20px;
    color: #999999;
    opacity: 1;
    margin: 0 auto;
    margin-top: 40px;
    // color: #ff7c65;
    // cursor: pointer;
    // transition: 0.5s;
  }
  .createdMind:hover {
    width: 292px;
    height: 40px;
    font-size: 14px;
    font-family: Noto Sans SC;
    font-weight: 400;
    line-height: 20px;
    color: #999999;
    opacity: 1;
    // color: #fd492b;
    // transition: 0.5s;
    // font-size: 22px;
  }
  .createdMindEmptyIcon {
    -webkit-filter: grayscale(100%);   
    -moz-filter: grayscale(100%);   
    -ms-filter: grayscale(100%);   
    -o-filter: grayscale(100%);     
    filter: grayscale(100%);
    filter: gray;
    width: 200px;
  }
  /* .my-mind-content-2 .more-function{
    margin-left: auto;
    margin-top: 5px;
    margin-right: 5px;
    color: #666;
  } */
}
</style>
