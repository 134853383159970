<template>
  <div
    class="content-box"
    ref="myMindContent"
    @contextmenu.prevent="rightClickMenu($event)"
  >
    <div class="normal-box" style="margin-top: 20px">
      <div class="content-header" @contextmenu.stop.prevent="">
        <div class="left-button">
          <div class="left-button-crumbs">
            <div
              class="mind-load"
              v-for="(item, index) in crumbsArr"
              :key="index"
              @click="parentFile(item, index)"
            >
              <template v-if="index != crumbsArr.length - 1">
                <div>
                  {{ item.name }}
                </div>
                <img
                  class="mind-load-img"
                  src="../../assets/img/myMindIcon/right-arrow.svg"
                  alt=""
                />
              </template>
              <template v-else>
                <div class="mind-load-last-text">
                  {{ item.name }}
                </div>
              </template>
            </div>
          </div>
          <!-- 我的文件夹 / a文件夹 / 树形思维导图1 -->
          <div
            ref="refeshMind"
            v-if="searchText == ''"
            @click="parentFile(fileObj, crumbsArr.length - 1)"
            class="refesh-mind-list"
            :class="[spinning ? 'rotate' : 'rotate1']"
          >
            <a-icon type="sync" />
          </div>
        </div>
        <section class="content-right">
          <div class="right-search">
            <div class="paper-btn" style="font-size: 24px" @click="addFolder()">
              <a-icon type="folder-add" />
            </div>
            <!-- <div class="check-icon-style" v-if="show == 2" @click="show = 1">
              <a-icon type="appstore" />
            </div>
            <div class="check-icon-style" v-if="show == 1" @click="show = 2">
              <a-icon type="unordered-list" />
            </div> -->
            <input
              v-model="searchText"
              class="search-box"
              type="text"
              @keyup.enter="searchFile()"
            />
            <div class="sea-btn">
              <a-button
                class="sea-btn-child"
                @click="searchFile()"
                shape="circle"
                icon="search"
              />
            </div>

            <span
              v-if="searchText != ''"
              @click="clearSearchText()"
              class="cleariconfont clear-search-text-icon"
              >&#xe605;</span
            >
          </div>
          <section class="display-mode">
            <!-- 排序 -->
            <a-select
              class="view-select"
              v-model="sortOrder"
              defaultValue="sortOrder"
              style="width: 116px; height: 28px; font-size: 13px"
              @change="sortOrderChange"
            >
              <a-icon slot="suffixIcon" style="color: #333" type="caret-down" />
              <a-select-option
                v-for="item of sortOrderList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
            <div
              class="view-mode"
              @click="switchViewMode()"
              @contextmenu.stop.prevent="switchViewMode()"
            >
              <img
                v-if="!viewMode"
                src="../../assets/img/myMindIcon/displayModeIcon/listMode.svg"
                alt="列表"
                style="height: 12px"
              />
              <img
                v-else
                src="../../assets/img/myMindIcon/displayModeIcon/iconMode.svg"
                alt="图标"
                style="height: 12px"
              />
            </div>
          </section>
        </section>
      </div>

      <MyMindContent
        ref="myMindContentCom"
        :show="show"
        :data="data"
        :showPop="showPop"
        class="father-login"
        @removeFile="removeFile"
        @folderRenameMap="folderRenameMap"
        @copyMap="copyMap"
        @moveCheck="moveCheck"
        @pageFile="pageFile"
        @repeatName="repeatName"
        @importGroup="importGroup"
        @importArchive="importArchive"
        @closeRightMenu="closeRightMenu"
      />
      <!-- <div v-show="spinning" class="loading-box">
        <a-spin
          class="loading-child"
          tip="Loading..."
          :spinning="spinning"
          :delay="200"
        >
        </a-spin>
      </div> -->

      <!-- <div @click="test()">a标签跳转</div> -->
      <a class="target" ref="target" href="" target="_blank"></a>

      <!-- 新建文件夹 -->
      <a-modal
        v-model="visible"
        :title="getString(strings.My_Mind_Create_Folder)"
        :ok-text="getString(strings.Global_Ok)"
        :cancel-text="getString(strings.Global_Cancel)"
        centered
        @ok="hideModal()"
      >
        <div class="file-color-class">
          <div
            @click="checkCreatedColor(0)"
            class="file-color-class-child color-blue"
          >
            <a-icon
              class="file-color-class-icon"
              v-if="saveCreatedColor == 0"
              :class="saveCreatedColor == 0 ? 'file-color-class-check' : ''"
              type="check"
            />
          </div>
          <div
            @click="checkCreatedColor(1)"
            class="file-color-class-child color-yellow"
          >
            <a-icon
              class="file-color-class-icon"
              v-if="saveCreatedColor == 1"
              :class="saveCreatedColor == 1 ? 'file-color-class-check' : ''"
              type="check"
            />
          </div>
          <div
            @click="checkCreatedColor(2)"
            class="file-color-class-child color-red"
          >
            <a-icon
              class="file-color-class-icon"
              v-if="saveCreatedColor == 2"
              :class="saveCreatedColor == 2 ? 'file-color-class-check' : ''"
              type="check"
            />
          </div>
          <div
            @click="checkCreatedColor(3)"
            class="file-color-class-child color-green"
          >
            <a-icon
              class="file-color-class-icon"
              v-if="saveCreatedColor == 3"
              :class="saveCreatedColor == 3 ? 'file-color-class-check' : ''"
              type="check"
            />
          </div>
          <div
            @click="checkCreatedColor(4)"
            class="file-color-class-child color-gray"
          >
            <a-icon
              class="file-color-class-icon"
              v-if="saveCreatedColor == 4"
              :class="saveCreatedColor == 4 ? 'file-color-class-check' : ''"
              type="check"
            />
          </div>
        </div>
        <a-input
          v-model="fileName"
          ref="fileInput"
          :placeholder="getString(strings.My_Mind_Please_Enter_The_Folder_Name)"
          @keyup.enter="hideModal()"
        />
      </a-modal>
      <!-- 文件夹重命名 -->
      <a-modal
        v-model="repeatModal"
        :ok-text="getString(strings.Global_Ok)"
        :cancel-text="getString(strings.Global_Cancel)"
        :title="getString(strings.User_Folder_Rename)"
        centered
        @ok="hideRepeatModal()"
      >
        <div class="file-color-class">
          <div
            @click="checkFileColor(0)"
            class="file-color-class-child color-blue"
          >
            <a-icon
              class="file-color-class-icon"
              v-if="saveFileColor == 0"
              :class="saveFileColor == 0 ? 'file-color-class-check' : ''"
              type="check"
            />
          </div>
          <div
            @click="checkFileColor(1)"
            class="file-color-class-child color-yellow"
          >
            <a-icon
              class="file-color-class-icon"
              v-if="saveFileColor == 1"
              :class="saveFileColor == 1 ? 'file-color-class-check' : ''"
              type="check"
            />
          </div>
          <div
            @click="checkFileColor(2)"
            class="file-color-class-child color-red"
          >
            <a-icon
              class="file-color-class-icon"
              v-if="saveFileColor == 2"
              :class="saveFileColor == 2 ? 'file-color-class-check' : ''"
              type="check"
            />
          </div>
          <div
            @click="checkFileColor(3)"
            class="file-color-class-child color-green"
          >
            <a-icon
              class="file-color-class-icon"
              v-if="saveFileColor == 3"
              :class="saveFileColor == 3 ? 'file-color-class-check' : ''"
              type="check"
            />
          </div>
          <div
            @click="checkFileColor(4)"
            class="file-color-class-child color-gray"
          >
            <a-icon
              class="file-color-class-icon"
              v-if="saveFileColor == 4"
              :class="saveFileColor == 4 ? 'file-color-class-check' : ''"
              type="check"
            />
          </div>
        </div>
        <a-input
          v-model="newFileName"
          ref="repeatInput"
          :placeholder="getString(strings.My_Mind_Please_Enter_The_Folder_Name)"
          @keyup.enter="hideRepeatModal()"
        />
      </a-modal>
      <!-- 导图重命名 -->
      <a-modal
        v-model="folderRenameModal"
        :ok-text="getString(strings.Global_Ok)"
        :cancel-text="getString(strings.Global_Cancel)"
        :title="getString(strings.User_Folder_Rename)"
        centered
        @ok="hideFolderRenameModal()"
      >
        <!-- <div class="file-color-class">
        </div> -->
        <a-input
          v-model="newName"
          ref="folderRenameInput"
          :placeholder="getString(strings.My_Mind_Please_Enter_The_Folder_Name)"
          @keyup.enter="hideFolderRenameModal()"
        />
      </a-modal>
      <!-- <a-modal v-model="moveFile"  ok-text="确认" cancel-text="取消" @ok="hideMoveFile">
        

      </a-modal> -->
      <!-- 移动到 -->
      <MyMoveFile
        :moveFile="moveFile"
        :fileData="moveData"
        :crumbsArr="moveCrumbsArr"
        :shiftOut="shiftOut"
        @hideMoveFile="hideMoveFile"
        @getChildFile="getChildFile"
      />
      <!-- 活动弹窗 -->
      <Activtiy />
      <MyMindAddGroupModal
        :mindItem="mindItem"
        :show="showMindAddGroup"
        @hiddenMindAddGroup="hiddenMindAddGroup"
      />
      <!-- 归档 -->
      <MoveToArchive
        ref="moveToArchiveBox"
        :showMoveToArchive="showMoveToArchive"
        :archiveData="archiveData"
        :crumbsArr="archiveCrumbsArr"
        :moveToArchiveItem="moveToArchiveItem"
        @hideMoveArchive="hideMoveArchive"
        @getChildArchive="getChildArchive"
        @getArvhiveObj="getArvhiveObj"
        @archiveParentFile="archiveParentFile"
      />
      <!-- 首页新手引导 -->
      <!-- <HomeNewGuide /> -->
      <!-- 右键菜单 -->
      <MyMindRightMenu
        ref="myMindRightMenu"
        :style="{ visibility: mindRightMenu ? 'visible' : 'hidden' }"
        :viewMode="viewMode"
        :rightMenuSite="rightMenuSite"
        :rightMenuSize="rightMenuSize"
        :sortOrderList="sortOrderList"
        :myMindWidth="myMindWidth"
        :myMindHeight="myMindHeight"
        @rightMenuFun="rightMenuFun"
        @sortOrderChange="sortOrderChange"
        v-clickoutside="closeRightMenu"
        @continuousShowRightMenu="continuousShowRightMenu"
        @timingHideRightMenu="timingHideRightMenu"
      />
    </div>
  </div>
</template>

<script>
import {
  postMindmapNewDir,
  postMindmapUploadDir,
  postMindmapAlias,
  postMindmapCopy,
  postMindmapDelete,
  postMindmapGetEntity,
  postMindmapQuery,
  postMindmapMove,
  postMindmapArchive,
} from "../../common/netWork/mind_map_api";

import { postMindmapQueryArchives } from "../../common/netWork/networkcache";
import { mapMutations } from "vuex";

import MyMindContent from "./myMindChid/MyMindContent";
import MyMoveFile from "./myMindChid/MyMoveFile";
import MoveToArchive from "./myMindChid/MoveToArchive";
import Activtiy from "../activtiy/Activtiy";
import getString from "../../common/lang/language";
import strings from "../../common/lang/strings";
import ShoppingModel from "../shopping/ShoppingModel";
import Colors from "../../utils/Colors";
import { getJwt, md5 } from "../../common/netWork/base";
import MyMindAddGroupModal from "./myMindChid/MyMindAddGroupModal";
import MyMindRightMenu from "./myMindChid/MyMindRightMenu";
import HomeNewGuide from "../complieComponents/noviceGuidance/HomeNewGuide.vue";
import { postMindmapListDir } from "../../common/netWork/networkcache";
import XJMindFIleImport from '../../core/core/dataformat/fileImport/XJMindFIleImport';
import HistoryForMyMindData from '../../viewmodel/core/tools/mapfile/data/HistoryForMyMindData';
import MindMapZipConstant from '../../viewmodel/core/tools/mapfile/MindMapZipConstant';
import Logger from '../../utils/Logger';
import HashMap from '../../viewmodel/core/base/HashMap';
import LoadLocalXJMind from "../../viewmodel/core/tools/mapfile/LoadLocalXJMind";

function ajaxPromise(callback, params, that) {
  return new Promise(function (resolve, reject) {
    callback(
      params,
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
        that.$message.error(error.desc);
      }
    );
  });
}

export default {
  inject: ["reload"],
  components: {
    MyMindContent,
    MyMoveFile,
    MoveToArchive,
    Activtiy,
    ShoppingModel,
    MyMindAddGroupModal,
    HomeNewGuide,
    MyMindRightMenu,
  },
  data() {
    return {
      spinning: false, //是否展示加载动画
      deg: 360, //旋转360度
      refeshMindTimer: null,
      transitionTransform: true,
      activityAccout: null,
      fileObj: {
        id: "",
      }, //当前文件夹的信息
      crumbsArr: [{ id: "", name: getString(strings.My_map) }], //面包屑数组
      fileIndex: 0, //当前文件夹所处的层级，根目录为0级
      show: 2, //查看模式，1 列表 2 图标
      visible: false,
      hiddenVal: 0,
      // 每一级的子文件
      data: null,
      fileName: "",
      newFileName: "",
      newName: "",
      repeatModal: false,
      folderRenameModal: false, //导图重命名
      moveFile: false, //是否展示移动到文件夹
      repeatObj: {}, //要修改名字的文件夹对象
      mindNameObj: {}, //要修改名字的导图对象
      saveCreatedColor: 0, //创建文件夹的颜色
      saveFileColor: 1, //修改文件夹的颜色
      shiftOut: {}, //被移动的文件或文件夹对象。
      shiftIn: { id: "" }, //要移入的文件对象。
      moveData: [], //移动文件夹的数据。里面有所有的子文件夹。
      searchText: "",
      showPop: 0,
      moveCrumbsArr: [{ id: "", name: getString(strings.My_map) }], //移动的面包屑数组
      strings,
      showMindAddGroup: false, //是否展示添加组
      mindItem: null, //要添加进入组的导图数据
      showMoveToArchive: false, //归档
      archiveData: [], //归档列表
      archiveCrumbsArr: [
        {
          id: "",
          name:
            getString(strings.Mind_Edit_Mine) + getString(strings.Mind_Archive),
        },
      ], //归档的面包屑数组
      moveToArchiveItem: null, //要归档的导图数据
      lastIndexBookcase: 0, //归档上一批数据的最后一个index
      loadMoreDataTime: null,
      loadMoreDataFlag: false, //是否可以请求归档列表更多数据
      pageSize: 45, //归档列表一页显示数据数量
      defaultSortData: [], //默认排序数据
      sortOrder: 0, //排序方式
      sortOrderList: [
        {
          label: getString(strings.Default_Sort), //"默认排序"
          value: 0,
        },
        {
          label: getString(strings.Mind_Name) + " A-Z", //名称 A-Z
          value: 1,
        },
        {
          label: getString(strings.Edit_Time), //"编辑时间"
          value: 2,
        },
      ],
      viewMode: false, //查看模式，true 列表 false 图标
      mindRightMenu: false, //右键菜单
      rightMenuSite: {
        left: 200,
        top: 200,
      }, //右键菜单位置
      rightMenuSize: {
        width: 0,
        height: 0,
      }, //右键菜单大小
      myMindWidth: 0, //当前组件宽度
      myMindHeight: 0, //当前组件高度
      rightMenuTimer: null,
      parentEntityId:"",
    };
  },
  created() {
    if (
      window.navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i // midp|iphone os|ipad|ucweb|windows mobile|
      )
    ) {
      this.$router.push("/download");
    } else {
    }

    //首次进入页面获取数据获取文件
    this.pullAllMind("", (res) => {
      this.parentEntityId = "";
      this.mindData(res.entities);
    });
  },
  mounted() {
    this.uploadLoadingStyle();
    this.getViewMode();
  },
  methods: {
    ...mapMutations([
      "checkCreatedMindCrumbsArr",
      "isShowShoppingModel",
      "showLoginModel",
    ]),

    getString(i) {
      return getString(i);
    },
    getClass(o) {
      //判断数据类型
      return Object.prototype.toString.call(o).slice(8, -1);
    },

    deepCopy(obj) {
      //深拷贝
      var result,
        oClass = this.getClass(obj);
      if (oClass == "Object") result = {};
      //判断传入的如果是对象，继续遍历
      else if (oClass == "Array") result = [];
      //判断传入的如果是数组，继续遍历
      else return obj; //如果是基本数据类型就直接返回
      for (var i in obj) {
        var copy = obj[i];
        if (this.getClass(copy) == "Object") result[i] = this.deepCopy(copy);
        //递归方法 ，如果对象继续变量obj[i],下一级还是对象，就obj[i][i]
        else if (this.getClass(copy) == "Array")
          result[i] = this.deepCopy(copy);
        //递归方法 ，如果对象继续数组obj[i],下一级还是数组，就obj[i][i]
        else result[i] = copy; //基本数据类型则赋值给属性
      }
      return result;
    },

    pullAllMind(id, onOk, onFail) {
      //拉取文件夹下的文件
      let that = this;
      that.spinning = true;
      let moreThanASecond = false; //是否超过1秒
      setTimeout(() => {
        moreThanASecond = true;
      }, 1000);
      postMindmapListDir(
        { parentId: id },
        (res) => {
          onOk(res);
        },
        (error) => {
          if (onFail) {
            onFail(error);
          }
          that.$message.error(error.desc);
        },
        () => {
          if (moreThanASecond) {
            that.spinning = false;
          } else {
            setTimeout(() => {
              that.spinning = false;
            }, 1000);
          }
        }
      );
    },

    addFolderPull(obj) {
      //创建文件夹的请求
      let that = this;
      postMindmapNewDir(
        obj,
        (rs) => {
          that.$message.success(getString(strings.Message_Tips_Create_Success));
          //清空创建文件夹
          that.fileName = "";
          that.saveCreatedColor = 1;
          that.pullAllMind(obj.parentEntityId, (res) => {
            this.parentEntityId = obj.parentEntityId;
            that.mindData(res.entities);
          });
        },
        (error) => {
          that.$message.error(error.desc);
        }
      );
    },

    uploadFile(obj) {
      //修改文件夹
      let that = this;
      postMindmapUploadDir(
        obj,
        (res) => {
          this.$message.success(
            getString(strings.Message_Tips_Modification_Success)
          );
          //清空修改框
          this.saveFileColor = "";
          this.newFileName = "";
          this.repeatModal = false;
          let itemRename = this.data.find((item) => item.id == obj.entityId); //重命名本地数据，不请求接口
          if (itemRename) {
            itemRename.name = obj.name;
            itemRename.colorCode = obj.colorCode;
          }
          let itemRename2 = this.defaultSortData.find(
            (item) => item.id == obj.entityId
          ); //重命名默认排序数据
          if (itemRename2) {
            itemRename2.name = obj.name;
            itemRename2.colorCode = obj.colorCode;
          }
        },
        (error) => {
          that.$message.error(error.desc);
        }
      );
    },
    uploadMindName(obj) {
      //修改导图名
      let that = this;
      postMindmapAlias(
        obj,
        (res) => {
          this.$message.success(
            getString(strings.Message_Tips_Modification_Success)
          );
          //清空修改框
          this.newName = "";
          this.folderRenameModal = false;
          let itemRename = this.data.find((item) => item.id == obj.entityId); //重命名本地数据，不请求接口
          if (itemRename) {
            itemRename.name = obj.alias;
            itemRename.alias = obj.alias;
          }
          let itemRename2 = this.defaultSortData.find(
            (item) => item.id == obj.entityId
          ); //重命名默认排序数据
          if (itemRename2) {
            itemRename2.name = obj.alias;
            itemRename2.alias = obj.alias;
          }
        },
        (error) => {
          that.$message.error(error.desc);
        }
      );
    },
    mapCopy(obj) {
      //复制导图
      let that = this;
      return new Promise(function (resolve, reject) {
        postMindmapCopy(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            that.$message.error(error.desc);
            reject(error);
          }
        );
      });
    },

    deletFile(obj) {
      //删除文件
      let that = this;
      postMindmapDelete(
        obj,
        (res) => {
          that.$message.success(getString(strings.Message_Tips_Dlt_Success)); //删除成功
          if(this.fileObj != null) {
            let id = 'my-mind-list' + obj.mindMapId
            let element = document.getElementById(id)
            if (element != null && element.parentElement != null &&  element.parentElement.parentElement != null) {
              element.parentElement.parentElement.remove()
            } else {
              let obj = {};
              obj.item = this.fileObj;
              this.pageFile(obj)
            }
          } else {
            this.searchFile(false);
          }
          // this.searchFile(false);
        },
        (error) => {
          that.$message.error(error.desc);
        }
      );
    },

    currentFileMessage(obj) {
      //获取当前文件夹信息
      let that = this;
      postMindmapGetEntity(
        obj,
        (res) => {
          if (res === "") {
            //是根文件夹
            this.deletCrumbs(this.crumbsArr);
            this.fileObj = {
              id: "",
              name: "我的导图",
            };
          } else {
            this.fileObj = res;
            //所有父级存入面包屑
            this.refresh(res.parents, this.fileObj, this.crumbsArr);
          }
        },
        (error) => {
          that.$message.error(error.desc);
        }
      );
    },

    searchMessage(obj) {
      //搜索信息
      let that = this;
      return new Promise(function (resolve, reject) {
        postMindmapQuery(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            that.$message.error(error.desc);
            reject(error);
          }
        );
      });
    },

    moveMind(obj) {
      //搜索信息
      let that = this;
      postMindmapMove(
        obj,
        (res) => {
          let val = {};
          val.item = this.shiftIn;
          //刷新一次列表
          this.pageFile(val);
        },
        (error) => {
          that.$message.error(error.desc);
        }
      );
    },

    addFolder() {
      //创建文件夹弹框显示。
      this.visible = true;
      this.$nextTick(() => {
        this.$refs.fileInput.focus();
      });
    },

    clearSearchText() {
      this.searchText = "";
      this.searchFile();
    },

    searchFile(falg = true) {
      //搜索导图
      if (this.searchText == "") {
        //搜索内容为空
        this.pullAllMind(
          "",
          (res) => {
            let entities = res.entities;
            this.parentEntityId = "";
            this.mindData(entities);
            this.deletCrumbs(this.crumbsArr);
            this.fileObj = {
              id: "",
              name: "我的导图",
            };
          },
          null,
          () => {
            this.spinning = false;
          }
        );
      } else {
        let obj = {};
        obj.query = this.searchText;
        obj.parentId = "";
        this.searchMessage(obj)
          .then((data) => {
            if (falg) {
              this.$message.success(
                getString(strings.Message_Tips_Selecte_Success)
              );
            }
            let entities = data.entities;
            this.parentEntityId = "";
            this.mindData(entities);
            //清空面包屑
            this.deletCrumbs(this.crumbsArr);
            this.fileObj = {
              id: "",
              name: "我的导图",
            };
          })
          .finally(() => {});
      }
    },
    //重命名
    folderRenameMap(val) {
      if (val.item.alias == "" || val.item.alias == null) {
        this.newName = val.item.name;
      } else {
        this.newName = val.item.alias;
      }
      this.mindNameObj = val;
      this.folderRenameModal = true;
      this.$nextTick(() => {
        this.$refs.folderRenameInput.focus();
      });
    },
    // 复制导图
    copyMap(val) {
      let obj = {};
      obj.entityId = val.item.id;
      this.mapCopy(obj).then((data) => {
        this.$message.success(getString(strings.Message_Tips_Copy_Success));
        return this.pullAllMind(this.fileObj.id, (res) => {
          this.mindData(res.entities);
        });
      });
    },
    // 移动文件逻辑
    moveCheck(val) {
      this.moveFile = true;
      this.shiftOut = val.item;
      this.moveData = this.deepCopy(this.data);
      this.moveCrumbsArr = this.deepCopy(this.crumbsArr);
      this.shiftIn = this.deepCopy(this.fileObj);
    },

    //删除文件或者文件夹
    removeFile(val) {
      let childeNumberString = "";
      if (val.item.isDir) {
        childeNumberString =
          getString(strings.Mind_Folder_Has) +
          val.item.childrenNum +
          getString(strings.Mind_Sub_File);
      }
      let that = this;
      this.$confirm({
        title: getString(strings.Mind_Mindmap_Deleted_Forever) + "?", //删除
        content:
          getString(strings.Mind_Confirm_Deleted) + " ? " + childeNumberString,
        okText: getString(strings.Mind_Edit_Confirm),
        cancelText: getString(strings.Mind_Group_Cancel),
        centered: true,
        style: {
          borderRadius: "20px",
        },
        onOk() {
          let obj = {};
          obj.entityId = val.item.id;
          obj.mindMapId = val.item.mindMapId;
          that.deletFile(obj);
        },
        onCancel() {},
      });
    },

    //隐藏添加组模态框
    hiddenMindAddGroup() {
      this.showMindAddGroup = false;
    },

    importGroup(val) {
      this.mindItem = val.item;
      this.showMindAddGroup = true;
    },
    importArchive(val) {
      //未登录
      if (!getJwt("jwt")) {
        this.showLoginModel(true);
        return;
      }
      this.getArvhiveList({ id: "" });
      this.moveToArchiveItem = val.item;
      this.showMoveToArchive = true;
      this.loadMoveToArvhiveEvent();
    },

    //修改颜色
    checkFileColor(val) {
      this.saveFileColor = val;
    },

    //改变颜色
    checkCreatedColor(val) {
      this.saveCreatedColor = val;
    },
    // 重命名文件夹
    hideRepeatModal() {
      if (this.newFileName == "") {
        this.$message.error(
          getString(strings.Message_Tips_Profile_Name_No_Null)
        );
        return;
      }
      let obj = {};
      obj.name = this.newFileName;
      obj.entityId = this.repeatObj.item.id;
      obj.colorCode = this.saveFileColor;
      this.uploadFile(obj);
    },
    //重命名导图
    hideFolderRenameModal() {
      let obj = {};
      obj.alias = this.newName;
      obj.entityId = this.mindNameObj.item.id;
      this.uploadMindName(obj);
    },

    //创建文件夹，
    hideModal() {
      if (this.fileName == "") {
        this.$message.error(
          getString(strings.Message_Tips_Profile_Name_No_Null)
        );
        return;
      }
      let obj = {};
      obj.parentEntityId = this.fileObj.id;
      obj.name = this.fileName;
      obj.colorCode = this.saveCreatedColor;
      this.visible = false;
      this.addFolderPull(obj);
    },

    hideMoveFile(obj) {
      this.moveFile = obj.show;
      if (obj.move == 1) {
        let reqObj = {};
        reqObj.entityId = this.shiftOut.id;
        reqObj.toParentId = this.shiftIn.id;
        this.moveMind(reqObj);
      }
    },

    getChildFile(val) {
      //点击文件夹时触发这个
      this.pullAllMind(
        val.item.id,
        (res) => {
          let obj = {};
          this.moveData = res.entities;
          //将当前文件夹的信息存到fileObj
          this.shiftIn = val.item;
          //获取当前文件夹信息;
          obj.entityId = val.item.id;
          if (obj.entityId == "") {
            //如果点击的是根文件夹
            return "";
          } else {
            return this.currentFileMessage(obj);
          }
        },
        (data) => {
          if (data === "") {
            //是根文件夹
            this.deletCrumbs(this.moveCrumbsArr);
            this.shiftIn = {
              id: "",
              name: "我的导图",
            };
          } else {
            this.shiftIn = data;
            //所有父级存入面包屑
            this.refresh(data.parents, this.shiftIn, this.moveCrumbsArr);
          }
        }
      );
    },
    clearArchiveData() {
      this.getArvhiveList({ id: "" });
      this.archiveCrumbsArr = [
        {
          id: "",
          name:
            getString(strings.Mind_Edit_Mine) + getString(strings.Mind_Archive),
        },
      ];
    },
    archiveParentFile(item, index) {
      if (index == 0) {
        this.clearArchiveData();
      } else {
        this.openFather(index, this.archiveCrumbsArr);
        // this.checkedBookcaseObj = item
        this.getArvhiveList(item);
      }
    },
    getArvhiveList(val) {
      // this.spinning = true;
      const obj = {
        parentId: val.id,
        lastIndex: 0,
        dirOnly: true,
      };
      postMindmapQueryArchives(
        obj,
        (res) => {
          this.archiveData = res.archives;
          if (this.archiveData && this.archiveData.length > 0) {
            this.archiveData.forEach((item) => {
              item.createdColorDecimal = Colors.getUiColor(item.color);
              let date = new Date(item.cerateTime * 1000);
              item.createTime = this.dateFormat("YYYY/mm/dd HH:MM", date);
            });
            // console.log(this.archiveData);
            if (this.archiveData.length >= this.pageSize) {
              this.loadMoreDataFlag = true;
              this.lastIndexBookcase =
                this.archiveData[this.archiveData.length - 1].index;
            }
          }
        },
        (e) => {
          this.$message.error(e.desc);
        },
        () => {
          // this.spinning = false;
        }
      );
    },
    loadMoreMoveToArvhiveList(val) {
      if (this.loadMoreDataFlag) {
        const obj = {
          parentId: val.id,
          lastIndex: this.lastIndexBookcase,
          dirOnly: true,
        };
        // console.log(obj);
        postMindmapQueryArchives(
          obj,
          (res) => {
            if (!!res.archives && res.archives.length > 0) {
              this.archiveData = [...this.archiveData, ...res.archives];
              this.loadMoreDataFlag = true;
              this.lastIndexBookcase =
                this.archiveData[this.archiveData.length - 1].index;
            } else {
              this.loadMoreDataFlag = false;
            }
            //console.log(this.archiveData,this.lastIndexBookcase);
          },
          (e) => {
            this.$message.error(e.desc);
          }
        );
      }
    },
    loadMoveToArvhiveEvent() {
      this.$nextTick(() => {
        let box = this.$refs.moveToArchiveBox.$refs.moveToArchive;
        if (box) {
          const onMouseWheel = (e) => {
            clearTimeout(this.loadMoreDataTime);
            this.loadMoreDataTime = setTimeout(() => {
              let ev = e || window.event;
              let down = true; // 定义一个标志，当滚轮向下滚时，执行一些操作
              down = ev.wheelDelta ? ev.wheelDelta < 0 : ev.detail > 0;
              if (down) {
                this.loadMoreMoveToArvhiveList(
                  this.archiveCrumbsArr[this.archiveCrumbsArr.length - 1]
                );
              }
            }, 100);
          };
          box.removeEventListener("mousewheel", onMouseWheel, true);
          box.addEventListener("mousewheel", onMouseWheel, true);
        }
      });
    },
    getChildArchive(val) {
      //点击文件夹时触发这个
      // this.spinning = true;
      const obj = {
        parentId: val.id,
        lastIndex: 0,
        dirOnly: true,
      };
      let isSecondCallback = false;
      postMindmapQueryArchives(
        obj,
        (res) => {
          this.archiveData = res.archives;

          if (!isSecondCallback) {
            this.archiveCrumbsArr.push({
              id: val.id,
              name: val.name,
            });
          }
          isSecondCallback = true;
          if (this.archiveData && this.archiveData.length > 0) {
            this.archiveData.forEach((item) => {
              item.createdColorDecimal = Colors.getUiColor(item.color);
              let date = new Date(item.cerateTime * 1000);
              item.createTime = this.dateFormat("YYYY/mm/dd HH:MM", date);
            });
            if (this.archiveData.length >= this.pageSize) {
              this.loadMoreDataFlag = true;
              this.lastIndexBookcase =
                this.archiveData[this.archiveData.length - 1].index;
            }
          }
        },
        (e) => {
          this.$message.error(e.desc);
        },
        () => {
          // this.spinning = false;
        }
      );
    },
    hideMoveArchive() {
      this.showMoveToArchive = false;
      this.clearArchiveData();
    },
    //确定归档
    getArvhiveObj(item) {
      // console.log(item.id);
      if (!item.id) {
        item.id = this.archiveCrumbsArr[this.archiveCrumbsArr.length - 1].id;
        if (item.id == "") {
          this.$message.warning(
            getString(strings.Please_Select_Archive_Location)
          );
          return;
        }
      }
      const obj = {
        parentId: item.id,
        mindMapId: this.moveToArchiveItem.mindMapId,
      };
      ajaxPromise(postMindmapArchive, obj, this).then((res) => {
        this.$message.success(getString(strings.Added_Successfully));
        this.showMoveToArchive = false;
        this.clearArchiveData();
        this.parentFile(this.fileObj, this.fileIndex);
        this.reload();
      });
    },
    //打开修改文件夹名模态框
    repeatName(obj) {
      this.repeatModal = true;
      this.newFileName = obj.item.name;
      this.saveFileColor = obj.item.colorCode;
      this.repeatObj = obj;
      this.$nextTick(() => {
        this.$refs.repeatInput.focus();
      });
    },

    //用于打开新页面
    test() {
      let target = this.$refs.target;
      target.setAttribute("href", window.location.origin + "/TestCanvas");
      target.click();
    },

    pageFile(val) {
      //点击文件夹时触发这个
      this.pullAllMind(val.item.id, (res) => {
        let obj = {};
        let entities = res.entities;
        this.parentEntityId = val.item.id;
        this.mindData(entities);
        //将当前文件夹的信息存到fileObj
        this.fileObj = val.item;
        //获取当前文件夹信息;
        obj.entityId = val.item.id;
        if (obj.entityId == "") {
          //如果点击的是根文件夹
          return "";
        } else {
          this.currentFileMessage(obj);
        }
      });
    },

    parentFile(item, index) {
      this.fileIndex = index;
      let obj = {};
      obj.item = item;
      this.openFather(index, this.crumbsArr);
      this.pageFile(obj);
      this.searchText = "";
    },

    openFather(index, crumbs) {
      // 打开祖先级
      crumbs.splice(index + 1, crumbs.length - index);
    },

    refresh(parent, current, crumbs) {
      //更新面包屑
      this.deletCrumbs(crumbs);
      crumbs.push.apply(crumbs, parent);
      //由于上面只有父元素，所以要将当前文件夹也要放到面包屑中
      crumbs.push(current);
    },

    deletCrumbs(crumbs) {
      //清空除根目录数组。
      crumbs.splice(1, crumbs.length);
    },

    getSearch(k) {
      // 获取地址栏内容
      var str = window.location.href.split("?")[1];
      // 解码成中文
      str = decodeURIComponent(str);
      var arr = str.split("&");
      var obj = {};
      // 获取键和值
      arr.forEach(function (v, i) {
        var key = v.split("=")[0];
        var value = v.split("=")[1];
        obj[key] = value;
      });
      return obj[k];
    },

    hide() {
      this.visible = false;
    },

    ClickShowData() {
      //发送请求
      // postTemplateList({page:0})
    },

    hidden(val) {},

    uploadLoadingStyle() {
      //用css实在改不了动画的颜色。只能上js了。
      let arr = document.querySelectorAll(".ant-spin-dot-item");
      for (let i = 0; i < arr.length; i++) {
        arr[i].style.background = "#FD492B";
      }
    },

    dateFormat(fmt, date) {
      let ret;
      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },

    removeRenameFile() {

    },
    // 最近创作列表
    mindData(entities) {
      // if (this.$tools.isElectron()) {
      //   let that = this;
      //   LoadLocalXJMind.appPath = this.$router.appPath
      //   new XJMindFIleImport(null, null, this.$router.appPath).loadHistoryData().then((historyData) => {
      //     if (historyData != null && historyData.list != null && historyData.list.length > 0) {
      //       for (let index = 0; index < historyData.list.length; index++) {
      //         const element = historyData.list[index];
      //         let uploadMindmap = false;
      //         for (let j = 0; j < entities.length; j++) {
      //           if (entities[j].mindMapId == element.id && element.id != null && element.id.length > 10) {
      //             entities[j].path = element.path
      //             entities[j].localpath = element.localpath;
      //             entities[j].subtitle = element.path
      //             uploadMindmap = true
      //             break;
      //           }
      //         }
      //         if (uploadMindmap || this.parentEntityId != "") {
      //           continue;
      //         }
      //         let cell = new HistoryForMyMindData()
      //         cell.name = element.name
      //         if (element.path != null && element.path.indexOf(MindMapZipConstant.MindZIPNewPathe) > -1) {
      //           cell.subtitle = "未保存文件"
      //         } else {
      //           cell.subtitle = element.path
      //         }
      //         cell.modifyTime = Math.ceil(element.time / 1000)
      //         cell.path = element.path
      //         cell.localpath = element.localpath;
      //         cell.id = element.id != null ? element.id : index + "-local";
      //         cell.mindMapId = index + "-local";
      //         cell.isHistory = true
      //         entities.push(cell)
      //       }
      //       // entities = entities.concat(historyEntities)
      //       that.data = entities;
      //       that.saveDefaultSortData(entities);
      //     } else {
      //       if (entities) {
      //         that.data = entities;
      //         that.saveDefaultSortData(entities);
      //       }
      //     }
      //   })
      // } else {
        if (entities) {
          this.data = entities;
          this.saveDefaultSortData(entities);
        }
      // }
    },
    //保存默认排序数据
    saveDefaultSortData(data) {
      this.defaultSortData = this.editTimeSort(
        JSON.parse(JSON.stringify(data))
      ); //深拷贝
      this.getSortOrder();
    },
    // 获取排序方式
    getSortOrder() {
      let sortOrderNum = parseInt(localStorage.getItem("sortOrderNum"));
      if (sortOrderNum) {
        this.sortOrder = sortOrderNum;
        this.sortOrderChange(sortOrderNum);
      } else {
        this.sortOrderChange(this.sortOrder);
      }
    },
    // 排序方式
    sortOrderChange(value) {
      localStorage.setItem("sortOrderNum", value);
      this.sortOrder = value;
      switch (parseInt(value)) {
        case 0: //默认排序
          this.defaultSortFun(this.defaultSortData);
          break;
        case 1: //名称 A-Z
          this.nameSortFun(this.defaultSortData);
          break;
        case 2: //编辑时间
          // let defaultData = JSON.parse(JSON.stringify(this.defaultSortData));//深拷贝
          this.data = this.defaultSortData;
          break;
      }
    },
    //默认排序
    defaultSortFun(defaultData) {
      let dataDir = defaultData.filter((item) => item.isDir);
      let dataMind = defaultData.filter((item) => !item.isDir);
      this.data = [...dataDir, ...dataMind];
    },
    //名称 A-Z 排序
    nameSortFun(defaultData) {
      let dataDir = defaultData.filter((item) => item.isDir);
      let dataMind = defaultData.filter((item) => !item.isDir);
      let myRegExp = new RegExp("^[a-zA-Z]"); //是不是字母
      let dataDirEN = dataDir.filter((item) => myRegExp.exec(item.name));
      let dataDirZH = dataDir.filter((item) => !myRegExp.exec(item.name));
      let dataMindEN = dataMind.filter((item) => myRegExp.exec(item.name));
      let dataMindZH = dataMind.filter((item) => !myRegExp.exec(item.name));
      nameSort(dataDirEN);
      nameSort(dataDirZH);
      nameSort(dataMindEN);
      nameSort(dataMindZH);
      function nameSort(data) {
        return data.sort((a, b) => a.name.localeCompare(b.name)); //a~z 排序
      }
      dataDir = [...dataDirEN, ...dataDirZH];
      dataMind = [...dataMindEN, ...dataMindZH];
      this.data = [...dataDir, ...dataMind];
      // console.log(dataDirEN,dataDirZH);
    },
    // 编辑时间排序
    editTimeSort(defaultData) {
      function compare(property, desc) {
        return function (a, b) {
          var value1 = a[property];
          var value2 = b[property];
          if (desc == true) {
            // 升序排列
            return value1 - value2;
          } else {
            // 降序排列
            return value2 - value1;
          }
        };
      }
      return defaultData.sort(compare("modifyTime", false));
    },
    //获取查看模式
    getViewMode() {
      let showViewMode = localStorage.getItem("showViewMode");
      if (showViewMode) {
        this.show = showViewMode;
        this.viewMode = showViewMode == 1 ? true : false;
      }
    },
    //切换查看模式
    switchViewMode() {
      this.viewMode = !this.viewMode;
      this.show = this.viewMode ? 1 : 2;
      localStorage.setItem("showViewMode", this.show);
    },
    // 显示右键菜单
    rightClickMenu(event) {
      event.preventDefault();
      let box = this.$refs.myMindRightMenu.$el; //菜单
      if (box) {
        let myMindContent = this.$refs.myMindContent; //当前组件
        if (myMindContent) {
          // let top = event.pageY - myMindContent.offsetParent.offsetTop;//鼠标在当前组件y轴
          // let left = event.pageX - myMindContent.offsetParent.offsetLeft;//鼠标在当前组件x轴
          let top = event.offsetY; //鼠标在当前组件y轴
          let left = event.offsetX; //鼠标在当前组件x轴
          let boxWidth = (this.rightMenuSize.width = box.offsetWidth); //菜单的宽度
          let boxHeight = (this.rightMenuSize.height = box.offsetHeight); //菜单的高度
          let windowWidth = (this.myMindWidth = myMindContent.offsetWidth); //当前组件宽度
          let windowHeight = (this.myMindHeight = myMindContent.offsetHeight); //当前组件宽度
          if (left + boxWidth > windowWidth) {
            left -= boxWidth;
          }
          if (top + boxHeight > windowHeight) {
            top -= boxHeight;
          }
          this.rightMenuSite = {
            left,
            top,
          };
          this.mindRightMenu = true;
          this.timingHideRightMenu();
          // console.log("left",left,"top",top,"event.scrollTop",event);
        }
      }
      const myMindContentCom = this.$refs.myMindContentCom;
      if (myMindContentCom) {
        myMindContentCom.outsideCloseMoreMenu(); //关闭导图菜单
      }
    },
    //继续显示右键菜单
    continuousShowRightMenu() {
      // clearTimeout(this.rightMenuTimer);
    },
    // 定时隐藏右键菜单
    timingHideRightMenu() {
      // this.rightMenuTimer = setTimeout(()=>{
      //     this.closeRightMenu()
      // },5000)
    },
    // 隐藏右键菜单
    closeRightMenu() {
      this.mindRightMenu = false;
    },
    // 右键菜单按钮
    rightMenuFun(type) {
      switch (type) {
        case "createFolder": //创建文件夹
          this.addFolder();
          break;
        case "createMap": //创建导图
          this.$router.push("/Home/CreatedFile");
          break;
        case "displayMode": //显示模式
          this.switchViewMode();
          break;
        default:
          return;
      }
      this.closeRightMenu();
    },
  },
  watch: {
    data(newVal, oldVal) {
      //在监听属性里面好像不用担心数组修改过后视图没有响应的问题。
      if (newVal) {
      }
    },
    crumbsArr(newVal, oldVal) {
      this.checkCreatedMindCrumbsArr(newVal);
    },
    "$store.state.userIfLogin"(newval, olval) {
      //登录状态改变
      if (newval == null) {
        return;
      } else {
        this.pullAllMind("", (res) => {
          let entities = res.entities;
          this.parentEntityId = "";
          this.mindData(entities);
        });
      }
    },
    "$store.state.isRefreshMyMindList"(newval, oldval) {
      this.parentFile(this.fileObj, this.fileIndex);
    },
    "$store.state.isAddFolder"(newval, oldval) {
      this.addFolder();
    },
    // spinning(newVal) {//旋转
    //   if (newVal) {
    //     let el = this.$refs.refeshMind;
    //     if (el) {
    //       this.transitionTransform = true;
    //       this.refeshMindTimer = setInterval(() => {
    //         el.style.transform = `rotate(${this.deg}deg)`;
    //         this.deg += 360;
    //       }, 1000);
    //     }
    //   } else {
    //     setTimeout(()=>{
    //       this.transitionTransform = false;
    //       clearInterval(this.refeshMindTimer);
    //     },2000)
    //   }
    // },
  },
  computed: {},
};
</script>

<style lang="less" >
.rotate {
  animation: mymove 1s infinite;
  -webkit-animation: mymove 1s infinite;
}

@keyframes mymove {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotate1 {
  transform: rotate(0deg);
  transition: all 1s;
}
@font-face {
  font-family: "iconfont";
  src: url("../../assets/font-icon/font_p53nofzredh/iconfont.eot");
  src: url("../../assets/font-icon/font_p53nofzredh/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/font_p53nofzredh/iconfont.woff2")
      format("woff2"),
    url("../../assets/font-icon/font_p53nofzredh/iconfont.woff") format("woff"),
    url("../../assets/font-icon/font_p53nofzredh/iconfont.ttf")
      format("truetype"),
    url("../../assets/font-icon/font_p53nofzredh/iconfont.svg#iconfont")
      format("svg");
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "fileiconfont";
  src: url("../../assets/font-icon/font_tclfjh9pcm/iconfont.eot");
  src: url("../../assets/font-icon/font_tclfjh9pcm/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/font_tclfjh9pcm/iconfont.woff2") format("woff2"),
    url("../../assets/font-icon/font_tclfjh9pcm/iconfont.woff") format("woff"),
    url("../../assets/font-icon/font_tclfjh9pcm/iconfont.ttf")
      format("truetype"),
    url("../../assets/font-icon/font_tclfjh9pcm/iconfont.svg#iconfont")
      format("svg");
}
.fileiconfont {
  font-family: "fileiconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "cleariconfont";
  src: url("../../assets/font-icon/font_ik9g4nleyx/iconfont.eot");
  src: url("../../assets/font-icon/font_ik9g4nleyx/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/font_ik9g4nleyx/iconfont.woff2") format("woff2"),
    url("../../assets/font-icon/font_ik9g4nleyx/iconfont.woff") format("woff"),
    url("../../assets/font-icon/font_ik9g4nleyx/iconfont.ttf")
      format("truetype"),
    url("../../assets/font-icon/font_ik9g4nleyx/iconfont.svg#iconfont")
      format("svg");
}
.cleariconfont {
  font-family: "cleariconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content-box {
  min-height: 100%;
  padding: 20px;
}

/* 加载动画开始 */
.loading-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}
.loading-child {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(2);
}
.ant-spin-dot-item {
  background-color: #fd492b;
}
.ant-spin {
  color: #fd492b;
}
.ant-spin .ant-spin-dot-item {
  background-color: #fd492b !important;
}
/* 加载动画结束 */

/* 头部开始 */
.mind-load {
  font-size: 20px;
  color: #999;
  display: flex;
  align-items: center;
  cursor: pointer;
  .mind-load-img {
    margin: 0 12px;
    margin-top: 2px;
    width: 16px;
    height: 16px;
    display: block;
  }
  .mind-load-last-text {
    color: #333;
  }
  &:last-child {
    cursor: default;
  }
}
// .mind-load:first-child{
//   cursor: pointer;
// }
.mind-load:nth-last-child(1) i {
  display: none;
}
.content-header {
  width: 100%;
  height: 50px;
  min-height: 50px;
  display: flex;
  margin-left: 20px;
  justify-content: space-between;
  .left-button {
    display: flex;
    align-items: center;
    line-height: 25px;
    .left-button-crumbs {
      display: flex;
      align-items: center;
      font-weight: 600;
    }
    .refesh-mind-list {
      line-height: 25px;
      font-size: 14px;
      margin-left: 16px;
      margin-top: 3px;
      cursor: pointer;
      // transition: transform 1s;
      &:active {
        color: #fd492b;
      }
    }
  }
  .content-right {
    display: flex;
    align-items: center;
    .right-search {
      padding-right: 20px;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      position: relative;
      .paper-btn {
        font-size: 25px;
        margin-left: 20px;
        cursor: pointer;
        /* position: absolute;
        margin-right: 1.7em; */
      }

      .check-icon-style {
        font-size: 25px;
        margin-left: 20px;
        cursor: pointer;
      }
      .sea-btn {
        position: absolute;
        left: 6px;
        width: 32px;
        height: 32px;
        top: 50%;
        transform: translate(0, -50%);
        .sea-btn-child {
          border: transparent;
          background-color: #f0f2f8;
        }
      }
      .search-box {
        width: 260px;
        border-radius: 15px;
        border: none;
        background: #ebebeb;
        height: 30px;
        padding-left: 40px;
        padding-right: 40px;
      }
      .search-box:focus {
        outline: none;
      }
      .clear-search-text-icon {
        display: block;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        font-size: 18px;
        left: 344px;
        color: #bbb;
        cursor: pointer;
        &:hover {
          color: #ff7354;
        }
        &:active {
          color: #d6301a;
        }
      }
    }
    .display-mode {
      padding-right: 20px;
      display: flex;
      .view-mode {
        width: 32px;
        height: 28px;
        cursor: pointer;
        border: 1px solid #ebebeb;
        background-color: #ebebeb;
        border-radius: 0px 4px 4px 0px;
        border-left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .ant-select-selection--single {
        height: 28px;
      }
      .ant-select-selection-selected-value {
        line-height: 28px;
      }
      .ant-select-selection {
        border: 1px solid #ebebeb;
        background-color: #ebebeb;
        border-radius: 4px 0px 0px 4px;
      }
    }
  }
}

/* 头部结束 */
/* 模板开始 */
.file-color-class {
  border-radius: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 300px;
  margin: 0 auto;
  margin-bottom: 20px;
}
.file-color-class-child {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  color: #fff;
  font-size: 28px;
  line-height: 40px;
}
.file-color-class-icon {
  display: none;
  line-height: 40px;
}

.file-color-class-check {
  display: block;
  line-height: 40px;
  height: 40px;
  margin-top: 3px;
}

.color-green {
  background-color: #43a102;
}
.color-blue {
  background-color: #4499ee;
}
.color-red {
  background-color: #ff6600;
}
.color-yellow {
  background-color: #eed205;
}
.color-gray {
  background-color: #707070;
}

/* 模板结束 */

/* 文件夹颜色开始 */
.file-color-green {
  color: #0bbb4e;
}
.file-color-blue {
  color: #6bb7da;
}
.file-color-red {
  color: #ff4d14;
}
.file-color-yellow {
  color: #ebcb3c;
}
.file-color-gray {
  color: #707070;
}
/* 文件夹颜色结束 */
/* 列表视图 */

.ant-modal-header {
  background: #efefef;
  padding: 10px 24px;
}
.ant-modal-close-x {
  height: 43px;
  line-height: 43px;
}
.ant-modal-footer {
  border-top: 0px;
}
.ant-select-open .ant-select-selection,
.ant-select-focused .ant-select-selection {
  border-color: #d9d9d9;
  box-shadow: none;
}
.ant-select-selection:hover,
.ant-select-selection:focus {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
  box-shadow: none;
}

.transition-transform-1 {
  transition: transform 1s;
}
</style>
